<template>
  <v-layout wrap>
    <v-card v-if="!showReport">
      <v-card-text>
      <v-dialog
        ref="dialog1"
        v-model="modal1"
        :return-value.sync="startDate"
        width="290px"
        lazy>
        <v-text-field
          slot="activator"
          v-model="getStartDate"
          label="Van"
          readonly
        ></v-text-field>
        <v-date-picker 
          locale="nl-nl"
          v-model="startDate"
          :max="endDate"
          show-week
          full-width
          @input="$refs.dialog1.save(startDate)" 
          scrollable>
        </v-date-picker>
      </v-dialog>
      <v-dialog
        ref="dialog2"
        v-model="modal2"
        :return-value.sync="endDate"
        width="290px"
        lazy>
        <v-text-field
          slot="activator"
          v-model="getEndDate"
          label="Tot en met"
          readonly
        ></v-text-field>
        <v-date-picker 
          locale="nl-nl"
          v-model="endDate"
          :min="startDate"
          show-week
          full-width
          @input="$refs.dialog2.save(endDate)" 
          scrollable>
        </v-date-picker>
      </v-dialog>
      <v-autocomplete
        v-if="clients.length > 0 && startDate && endDate"
        :items="items"
        item-text="name"
        return-object
        :search-input.sync="search"
        v-model="selectedClient"
        hide-no-data
        hide-details
        label="Selecteer een organisatie"
      ></v-autocomplete>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <hourBookingReport 
      v-if="showReport" 
      :hourBookings="hourBookings"
      :startDate="startDate"
      :endDate="endDate"
      :subject="selectedClient.name"
      :roles="roles"
      v-on:done="resetReport">
    </hourBookingReport>
    <v-dialog v-model="dialog" max-width="300px">
      <v-alert
        :value="true"
        type="error"
      >
        {{dialogText}}
      </v-alert>      
    </v-dialog>    
  </v-layout>
</template>

<script>
import hourBookingReport from '../../components/HourBookingReport.vue'
export default {
  name: 'Client',
  props: ['roles'],
  components: { 
    hourBookingReport
  },
  data () {
    return {
      search: null,
      items: [],
      clients: [],
      showReport: false,
      selectedClient: null,
      startDate: null,
      endDate: null,
      dialog1: false,
      dialog2: false,
      modal1: false,
      modal2: false,
      hourBookings: [],
      dialog: false,
      dialogText: ''
    }
  },
  watch: {
    search (val) {
      if (val == '') this.items = this.clients
      val && val !== this.select
    },
    selectedClient (val) {
      if (val !== null) {
        this.getReport()
      }
    },
    endDate () {
      if (this.startDate && this.endDate) this.getClients()
    },
    startDate (val) {
      var date = new Date(val)
      date.setMonth(date.getMonth()+1)
      date.setDate(0)
      this.endDate = date.toISOString().substr(0, 10)
      if (this.startDate && this.endDate) this.getClients()
    }
  },
  computed: {
    getStartDate () {
      return this.$moment(this.startDate).format('dddd D MMMM')
    },
    getEndDate () {
      return this.$moment(this.endDate).format('dddd D MMMM')
    } 
  },
  methods: {
    getClients () {
      this.selectedClient = null
      this.$http.get('api/organization/getbyactivty/' + this.startDate + '-' + this.endDate)
        .then(request => {
          this.clients = request.data
          this.items = this.clients
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    getReport () {      
      this.$http.get('api/report/C' + this.startDate + '-' + this.endDate + '-' + this.selectedClient.id)
        .then(request => {
          this.hourBookings = request.data
          this.showReport = true
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    resetReport() {
      this.showReport = false 
      this.showOverview = false
      this.selectedClient = null
      this.hourBookings = []
    }  
  },
  mounted: function () {
    var date = new Date()
    date.setDate(1)
    date.setMonth(date.getMonth()-1)
    this.startDate = date.toISOString().substr(0, 10)
  }
}
</script>